import { navigate } from "@reach/router"

const Routing = (props) => {
    let url = props.url;

    if (url.includes("en/")) {
        if(url.includes("contactez")) navigate("/fr/contactez-nous");
        else if(url.includes("partenaire")) navigate("/fr/devenez-partenaire");
        else if(url.includes("rayonnement")) navigate("/fr/rayonnement");
        else if(url.includes("services")) navigate("/fr/services");
        else navigate("/fr/");
    }
    else if(url.includes("fr/")) {
        if(url.includes("contactez")) navigate("/en/contactez-nous");
        else if(url.includes("partenaire")) navigate("/en/devenez-partenaire");
        else if(url.includes("rayonnement")) navigate("/en/rayonnement");
        else if(url.includes("services")) navigate("/en/services");
        else navigate("/en/");
    }
}

export default Routing
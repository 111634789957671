import React from "react"
import menuStyles from "../styles/menu.module.scss"
import { Link } from "gatsby"
import { useTranslation } from "../hooks/useTranslation"

const Menu = ({ open, onCloseMenu }) => {

    const windowLang = () => {
        if(typeof window !== `undefined`) {
            return window
        }
    }

    const t = useTranslation()

    const currentLang = windowLang() !== undefined && windowLang().location.href.includes("/en") ? "en" : "fr"

    return (
        <div className={`${menuStyles.menu} ${open ? menuStyles.open : ""}`}>
            <ul>
                <li className={menuStyles.outreach_link} onClick={onCloseMenu}>
                    <Link to={`${currentLang}/rayonnement`}>
                        <span>{t("menu.rayonnement")}</span>
                        <br/>
                        <span>{t("menu.rayonnementSecond")}</span>
                    </Link>
                </li>
                <li className={menuStyles.service_link} onClick={onCloseMenu}>
                    <Link to={`${currentLang}/services`} >
                        {t("menu.services")}
                    </Link>
                </li>
                <li className={menuStyles.contact_link} onClick={onCloseMenu}>
                    <Link to={`${currentLang}/contactez-nous`} >
                        {t("menu.contact")}
                    </Link>
                </li>
                <li className={menuStyles.partner_link} onClick={onCloseMenu}>
                    <Link to={`${currentLang}/devenez-partenaire`} >
                        <span>{t("menu.devenez")}</span>
                        <br/>
                        <span>{t("menu.partenaire")}</span>
                    </Link>
                </li>
                <li className={menuStyles.home_link} onClick={onCloseMenu}>
                    <Link to={`${currentLang}/`} >
                        {t("menu.home")}
                    </Link>
                </li>
            </ul>
            
        </div>
    )
}

export default Menu
import React, { useState } from "react"
import Header from "./header"
import Menu from "./menu"
import contentStyles from "../styles/body.module.scss"
import Socials from "./socials"

const Layout = ({children}) => {

    const [ isMenuOpen, setIsMenuOpen ] = useState(false);

    return (
        <div>
            <Header onOpenMenu={() => setIsMenuOpen(!isMenuOpen)} isMenuOpen={isMenuOpen} />
            <Socials />
            <Menu open={isMenuOpen} onCloseMenu={() => setIsMenuOpen(!isMenuOpen)} />
            <div className={contentStyles.container}>
                {children}    
            </div>
        </div>
    )
}

export default Layout 
import React, { useState } from "react"
import Axios from "axios"
import footerStyles from "../styles/footer.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "../hooks/useTranslation"
import { useIntl } from "react-intl"
import Routing from "../routing/routing"
import facebook from "../Images/facebook-icon.png"
import twitter from "../Images/twitter-icon.png"
import instagram from "../Images/instagram-icon.png"
import Marquee from "../components/marquee"
import plane from "../Images/SVG_web_illustrations/DTT_Icone_Courriel.svg"

const URL = "https://lc3byb763i.execute-api.ca-central-1.amazonaws.com/Prod/send";

const Footer = (props) => {

    const t = useTranslation();
    const intl = useIntl();
    const [email, setEmail] = useState("");

    const currentLang = intl.locale;
    const otherLang = currentLang == "fr" ? "en" : "fr";

    function redirect() {
        Routing({
            url: window.location.href
        })
    }

    const submitNewsLetterForm = async () => {
        if (email === "") return

        const body = {
            "toEmails": ["nicstaubin@gmail.com", "info@doctoctoc.ca"],
            "subject": "DocTocToc | New newsletter subscription",
            "message": `email: ${email}`
        };

        try {
            await Axios.post(URL, body);   
        } catch(e) {
            console.log(e);
        } finally {
            setEmail("");
        }
    }

    return (
        <div>
            <div className={footerStyles.bus_holder}>
                <div className={footerStyles.bus}/>
            </div>

            <footer className={footerStyles.footer} style={{ backgroundColor: props.color }}>
                <div className={footerStyles.content}>

                    <div className={footerStyles.socials}>
                        <span onClick={redirect}>{otherLang}</span>
                        <a href="https://www.facebook.com/allodoctoctoc/" target="_blank">
                            <img src={facebook} alt="facebook" />
                        </a>
                        <a href="https://twitter.com/allodoctoctoc" target="_blank">
                            <img src={twitter} alt="twitter" />
                        </a>
                        <a href="https://www.instagram.com/allodoctoctoc/?hl=fr-ca" target="_blank">
                            <img src={instagram} alt="instagram" />
                        </a>
                    </div>

                    <div className={footerStyles.top}>
                        <div>
                            <h4>{t("footer.abonne")}</h4>
                            <div className={footerStyles.email_input_holder}>
                                <input 
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    className={footerStyles.courrielText} 
                                    type="text" 
                                    placeholder={t("footer.courriel")}/>
                                <button className={email === "" ? footerStyles.disabled : ''} onClick={submitNewsLetterForm}>
                                    <img className={footerStyles.plane} src={plane} alt="send" />
                                </button>
                            </div>
                        </div>

                        <div>
                            <h4>{t("footer.contact")}</h4>
                            <a target="_blank" href="mailto:info@doctoctoc.ca" className={footerStyles.contact_button}> {t("footer.info")} </a>
                        </div>

                        <div>
                            <h4>{t("footer.soutien")}</h4>
                            <a target="_blank" href="https://app.simplyk.io/fr/donation-form/6ae5b923-05d8-471b-a093-f9636a887bc2" className={footerStyles.donation_button}>
                                <FontAwesomeIcon color="#f04e23" icon={faHeart} size="1x" style={{marginRight: 15}} />
                                {t("footer.donnez")} 
                            </a>
                        </div>
                    </div>

                    <div className={footerStyles.separator}/>

                    <div className={footerStyles.bottom}>
                        <div>
                            {t("footer.siteBy")} <a target="_blank" href="https://compagnieetcie.com/">Compagnie et cie</a> {t("footer.and")} <a target="_blank" href="https://www.thirdbridge.ca/">Third Bridge</a>
                        </div>

                        <div className={footerStyles.center}>
                            <Marquee>
                                {t("footer.marquee")}
                            </Marquee>
                        </div>

                        <div className={footerStyles.copyright}>{t("footer.copyright")}</div>
                    </div>

                </div>
            </footer>
        </div>
    )
}

export default Footer
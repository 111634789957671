import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "../hooks/useTranslation"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import headerStyles from "../styles/header.module.scss"
import doctoctoc from "../Images/DocTocToc_logos/DocTocToc_logo_couleur.svg"

const Header = ({ onOpenMenu, isMenuOpen }) => {

    const t = useTranslation();

    const windowLang = () => {
        if(typeof window !== `undefined`) {
            return window
        }
    }

    return (
        <header className={headerStyles.header}>
            <Link className={headerStyles.title} to={windowLang() !== undefined && windowLang().location.href.includes("/en") ? "/en/" : "/fr/"}>
                <img src={doctoctoc} alt="title" />
            </Link>
            <div className={headerStyles.button_holder}>
                <a href="https://app.simplyk.io/fr/donation-form/6ae5b923-05d8-471b-a093-f9636a887bc2" target="_blank" className={headerStyles.donation_button}>
                    <FontAwesomeIcon color="white" icon={faHeart} size="1x" />
                    {t("header.donations")}
                </a>
                <button onClick={onOpenMenu} className={headerStyles.menu_button}>
                    { !isMenuOpen && <FontAwesomeIcon className={headerStyles.bars_icon} color="black" icon={faBars} size="1x" /> }
                    { isMenuOpen && <FontAwesomeIcon className={headerStyles.close_icon} color="black" icon={faTimes} size="1x" /> }
                </button>
            </div>
        </header>
    )
}

export default Header
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import socialStyles from "../styles/body.module.scss"
import facebook from "../Images/facebook-icon.png"
import twitter from "../Images/twitter-icon.png"
import instagram from "../Images/instagram-icon.png"
import { useIntl } from "react-intl"
import Routing from "../routing/routing"


const Socials = () => {
    const intl = useIntl();

    const currentLang = intl.locale;
    const otherLang = currentLang == "fr" ? "en" : "fr";

    function redirect() {
        Routing({
            url: window.location.href
        })
    }

    return (
        <div className={socialStyles.side}>
            <div onClick={redirect} className={socialStyles.language_dropdown}>
                <span className={socialStyles.current_language}>{currentLang}</span>
                <FontAwesomeIcon color="black" icon={faCaretDown} size="1x" />
                <span className={socialStyles.other_language}>{otherLang}</span>
            </div>
            <div>
                <a href="https://www.facebook.com/allodoctoctoc/" target="_blank" className={socialStyles.facebook}>
                    <img style={{ width: "100%", height: "100%" }} src={facebook} alt="facebook" />
                </a>
                <a href="https://twitter.com/allodoctoctoc" target="_blank" className={socialStyles.twitter}>
                    <img style={{ width: "100%", height: "100%" }} src={twitter} alt="twitter" />
                </a>
                <a href="https://www.instagram.com/allodoctoctoc/?hl=fr-ca" target="_blank" className={socialStyles.instagram}>
                    <img style={{ width: "100%", height: "100%" }} src={instagram} alt="instagram" />
                </a>
            </div>
        </div>
    )
}

export default Socials
import React from "react"
import marqueeStyles from "../styles/marquee.module.scss"

const Marquee = ({ children, className }) => {
    return (
        <div className={`${marqueeStyles.marquee} ${className ? className : ''}`}>
            <div className={marqueeStyles.marquee_one}>
                {children}
            </div>
            <div className={marqueeStyles.marquee_two}>
                {children}
            </div>
        </div>
    )
}

export default Marquee